<script>
//import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
// import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useHttp from '@/comp-functions/useHttp'
import {formatCurrency, formatNumber} from '@/utils/formatter'
import { ref, onMounted } from '@vue/composition-api'
import { mapKeys, camelCase } from 'lodash'
import printInvoice from '@/components/print-invoice'
import printInvoiceSmu from '@/components/print-invoice-smu'
import RecentActivityOrder from "@/components/recent-activity-order";
import Swal from "sweetalert2";
//import i18n from "../../../i18n"

const DETAIL_ORDER = {
  oddGoodstypecode: 'GDTGNC', 
  oddGoodsTypeDescr: 'General Cargo',
  oddPackingtypecode: 'PACKRG', 
  oddPackingstypedesc: 'Karung',
  oddCollies: 3, 
  oddWeightPerColly: 15,
  oddWidth: 20, 
  oddHeight: 30, 
  oddLength: 40, 
  oddVolumePerColly: 0,
  oddChargeweightactual: 0,
  oddChargeweightround: 0,
  oddFreightcharge:0,
  oddSurchargepct:0,
  oddSurchargeamount:0,
  oddHandlingcharge:0,
  oddTotalcharge:0,
  oddRemarks: 'Remarkszz', 
  //oddChargearr:[]
  }

export default {
  page: {
    title: "Detail Order",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    //DatePicker,
    // Multiselect,
    RecentActivityOrder,
    Layout,
    PageHeader,
  },
  props:{
      p_ordno: String
  },
  data() {
    return {
      title: "Order Detail",
      items: [],
      select_label: "Select",
      checkedtnc : false
    };
  },
  
  setup(props){
    const { $get } = useHttp()
    const selectedSchedule = ref({})
    const configDropship = ref({})
    const orderRules = ref({})
    const { $put } = useHttp()

    let userLogin = JSON.parse(localStorage.getItem('user'))
    const userType = userLogin.usertype

    const form = ref({
      ordScheduleId: 1,
      ordCollies: 0,
      ordWeightactual: 0,
      ordWeightcharge: 0,
      ordWeight: 0,
      ordFreightcharge:0,
      ordSurcharge:0,
      ordHandlingcharge:0,
      ordHandlingdgcharge:0,
      ordAdminfee:0,
      ordDiscountpct:0,
      ordDiscountamount:0,
      ordVatcharge:0,
      ordGtotalcharge:0,
      ordShipperName: '',
      ordShipperAddress: '',
      ordShipperPhone: '',
      ordConsigneeName: '',
      ordConsigneeAddress: '',
      ordConsigneePhone: '',
      details: [],
      ordercharges: []
    })

     const createInvoice = async (inv_id) => {
      printInvoice(inv_id)
    }

     const createSmu = async (inv_id) => {
      printInvoiceSmu(inv_id)
     }

    const currentDetailOrder = ref({...DETAIL_ORDER})


    const getSchedule = async () => {
      const {data} = await $get({ url: 'master/schedule/' + form.value.ordScheduleId})
      selectedSchedule.value = data
      getVendorconfig()
    }

    const getVendorconfig = async () => {
      const {data} = await $get({ url: 'master/vendorconfigall/'+selectedSchedule.value.sch_vendor_id+'/'+selectedSchedule.value.originport.prt_id+'/'+selectedSchedule.value.sch_aircraft_type_code})
      orderRules.value = data.orderrule.vnc_configs.goods_validation
      configDropship.value = data.dropship.vnc_configs.dropship
    }

    const getOrder = async () => {
      if (typeof props.p_ordno !== 'undefined'){
        const {data} = await $get({ url: 'master/search_order/' + props.p_ordno})
        form.value = {...mapKeys(data , (val, key) => camelCase(key)), details:[]}
        form.value.details = data.orderDetail.map(prop => {
          return mapKeys(prop, (val, key) => camelCase(key))
        })
        console.log(form.value.ordno)
        if(typeof form.value.ordScheduleId !== 'undefined'){
          getSchedule()
          noOrder.value = form.value.ordId
        }
      }
    }

    const cancelInvoice = async () => {
    let dataSubmit = {
      p_ordno :  form.value.ordId
    }
      $put({
         url: 'master/order_status',
        data: dataSubmit
      })
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: 'Invoice status has been cancel',
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
    }


    onMounted(() => {
     getOrder()
    })

 const noOrder = ref()


 return{
   noOrder,
   selectedSchedule,
   orderRules, configDropship,
   form,
   currentDetailOrder,
    formatCurrency,
    formatNumber,
   createInvoice,
     createSmu,
   userType,
   cancelInvoice
 }
  },
  methods: {
    customLabelConsignee ({ consignee_name, consignee_address, consignee_phone }) {
      return `${consignee_name} – ${consignee_address} – ${consignee_phone}`
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="typeof selectedSchedule.vendor !== 'undefined'">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-3">
                {{selectedSchedule.vendor.name}} - {{selectedSchedule.sch_flight_no}} <br/>
                Departure {{selectedSchedule.sch_departure_date}}
              </div>
              <div class="col-xl-3 text-center">
                {{selectedSchedule.sch_departure_time}} <i class="ri-arrow-right-line"></i> {{selectedSchedule.sch_arrival_time}}<br/>
                {{selectedSchedule.originport.prt_locationname}} ({{selectedSchedule.originport.prt_initial}}) <i class="ri-arrow-right-line"></i> {{selectedSchedule.destinationport.prt_locationname}} ({{selectedSchedule.destinationport.prt_initial}})
              </div>
              <div class="col-xl-3 text-center">
                {{Math.floor(selectedSchedule.sch_duration/60)}}h {{Math.floor(selectedSchedule.sch_duration%60)}}m<br/>
                <div v-if="selectedSchedule.transit > 0">{{selectedSchedule.transit}} Transit</div>
                    <div v-else>Direct</div>
              </div>
              <div class="col-xl-3 text-center">
                <h5 class="mb-0">{{ formatCurrency(selectedSchedule.sch_price_kg) }}/kg</h5>
              </div>               
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Order No"
                    label-for="order_no"
                  >
                    <b-form-input v-model="form.ordNo" disabled></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="SMU No"
                    label-for="order_no"
                  >
                    <b-form-input v-model="form.ordManifestno" disabled></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Customer"
                    label-for="customer_id"
                  >
                    <b-form-input v-model="form.customer.name" disabled></b-form-input>
                  </b-form-group>
                </form>
              </div>
              <div class="col-12">
                  <div class="table-responsive">
                    <table class="table font-size-13 table-bordered">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Goods Type</th>
                          <th>Packing</th>
                          <th>Remarks</th>
                          <th>Collies</th>
                          <th>Total Chargeable Weight</th>
                          <th>Total Freight Charges</th>
                          <th>Total Surcharge</th>
                          <th>Total Handling Heavy Charge</th>
                          <th>Total Charge</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(detailOrder,index) in form.details" :key="index">
                          <th scope="row">{{index+1}}</th>
                          <td>{{detailOrder.goodsType.descr_en}}</td>
                          <td>{{detailOrder.packingType.descr_en}}</td>
                          <td>{{detailOrder.oddRemarks}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddCollies)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddChargeweightround*detailOrder.oddCollies)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddFreightcharge*detailOrder.oddCollies)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddSurchargeamount*detailOrder.oddCollies)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddHandlingcharge*detailOrder.oddCollies)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddTotalcharge)}}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr class="font-weight-bold">
                          <td class="text-right" colspan="4">Total</td>
                          <td class="text-right">{{formatNumber(form.ordCollies)}}</td>
                          <td class="text-right">{{formatNumber(form.ordWeight)}}</td>
                          <td class="text-right">{{formatNumber(form.ordFreightcharge)}}</td>
                          <td class="text-right">{{formatNumber(form.ordSurcharge)}}</td>
                          <td class="text-right">{{formatNumber(form.ordHandlingcharge)}}</td>
                          <td class="text-right">{{formatNumber(parseInt(form.ordFreightcharge) + parseInt(form.ordSurcharge) +parseInt(form.ordHandlingcharge))}}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div class="row">
                    <div class="offset-md-7 col-md-5">
                      <b-form-group
                        label-cols-sm="5"
                        label-cols-lg="5"
                        class="mb-0"
                        label="Handling DG Charge"
                        label-for=""
                        v-if="form.ordHandlingdgcharge > 0"
                      >
                        <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordHandlingdgcharge) }}</h6></div>
                      </b-form-group>
                      <hr class="m-0"/>
                      <b-form-group
                        label-cols-sm="5"
                        label-cols-lg="5"
                        class="mb-0"
                        label="Admin SMU"
                        label-for=""
                      >
                        <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordAdminfee) }}</h6></div>
                      </b-form-group>
                      <hr class="m-0"/>
                      <b-form-group
                        label-cols-sm="5"
                        label-cols-lg="5"
                        class="mb-0"
                        label="Admin Fee"
                        label-for=""
                      >
                        <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordVatcharge) }}</h6></div>
                      </b-form-group>
                      <hr class="m-0"/>
                      <b-form-group
                        label-cols-sm="5"
                        label-cols-lg="5"
                        class="mb-0"
                        label="Grand Total"
                        label-for=""
                      >
                        <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordGtotalcharge) }}</h6></div>
                      </b-form-group>
                      <hr class="m-0"/>
                    </div>
                  </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>

  <div class="row">
      <div class="col-12 text-center">
        <div class="button-items">
            <b-button id="smuprint" variant="success" @click="createSmu(form.ordId)">
                <i class="mdi mdi-file-download-outline align-middle ml-2"></i>
                Download SMU
            </b-button>
              <b-button id="invprint" variant="success" @click="createInvoice(form.invoice[0].inv_id)">
                <i class="mdi mdi-file-download-outline align-middle ml-2"></i>
                Download Invoice
              </b-button>
              <b-button v-if  = "userType === 'UTYINL'" variant="danger" @click="cancelInvoice">
                Cancel Order
              </b-button>
              <b-button v-if="typeof form.invoice[1] !== 'undefined'" class="ml-2" id="invprint" variant="success" @click="createInvoice(form.invoice[1].inv_id)">
                <i class="mdi mdi-file-download-outline align-middle ml-2"></i>
                Download Invoice (Order Adjustment)
              </b-button>
          </div>
        </div>
      </div>
<br/>
      <div class="row">
        <div class="col-lg-12">
          <RecentActivityOrder :idOrder="noOrder"/>
        </div>
      </div>


    <!-- end row -->

    <!-- end row -->
  </Layout>
</template>
